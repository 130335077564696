<template>
	<div class="dialog">
		<el-dialog :title="typeFlag=='add'?'添加':'修改'" :visible.sync="dialogVisible" width="600px" :before-close="close">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
				<el-form-item label="一级分类">
					<el-select size="small" v-model="ruleForm.parent_id" clearable @change="changeParent()">
						<el-option
                            v-for="item in catesList"
                            :key="item.id"
                            :label="item.cate_name"
                            :value="item.id">
                        </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="分类类型">
					<el-select size="small" v-model="ruleForm.type" :disabled="ruleForm.parent_id > 0" clearable>
						<el-option v-for="(val,key) in $constant.collegeType" :key="key" :label="val" :value="key"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="分类名称">
					<el-input size="small" v-model="ruleForm.cate_name"></el-input>
				</el-form-item>
				<el-form-item label="分类描述">
					<el-input size="small" v-model="ruleForm.cate_sketch"></el-input>
				</el-form-item>
				<el-form-item label="排序">
					<el-input-number size="small" v-model="ruleForm.sort" :min="0"></el-input-number>
				</el-form-item>
				<el-form-item label="分类背景照片" prop="cate_icon">
					<Qiniu :imgKey="ruleForm.cate_icon" @uploadSuccess="uploadSuccess"></Qiniu>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
					<el-button @click="close()">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>
<script>
	import { cateList, saveCate } from '@/api/college'
	import Qiniu from '@/components/qiniu.vue'
	export default {
		name: "ClassAdd",
		props: {
			dialogVisible: {
				type: Boolean,
				default: false
			},
			typeFlag: { // 判断是新建 编辑 详情
				type: String,
				default: 'add'
			},
			row: { // 判断是新建 编辑 详情
				type: Object,
				default: ()=>{
					return {}
				}
			},
		},
		data() {
			return {
				catesList: [],
				ruleForm: {
					parent_id: '',
					sort: 1,
					type: '',
					cate_name: '',
					cate_sketch: '',
					cate_icon: ''
				},
				rules: {
					// cate_icon: [
					// 	{ required: true, message: '请上传分类背景', trigger: 'blur' }
					// ],
				}
			}
		},
		components: {
			Qiniu
		},
		watch: {
			dialogVisible: {
				handler() {
					Object.keys(this.ruleForm).forEach(item=> {
						if(item == 'type') {
							this.ruleForm[item] = this.row[item] ? String(this.row[item]) : ''
						}else if(item == 'parent_id') {
							this.ruleForm[item] = this.row[item] ? this.row[item] : ''
						} else {
							this.ruleForm[item] = this.row[item]
						}
					})
				},
				immediate: true
			}
		},
		methods: {
			// 获取分类
			getallCate() {
				if(this.catesList.length > 0) return
				cateList().then((res) => {
					this.catesList = res.data.filter(item=>item.parent_id == 0)
				}).catch((err) => {
					console.log(err)
				})
			},
			// 一级分类修改
			changeParent() {
				this.ruleForm.type = this.ruleForm.parent_id ? String(this.catesList.find(item=>item.id == this.ruleForm.parent_id).type) : this.ruleForm.type 
			},
            // 确定
            submitForm(formName){
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.save()
					}
				});
			},
			save(){
				let body = this.ruleForm
				this.typeFlag == 'edit' ? body.id = this.row.id : ''
				saveCate(body).then((res) => {
					if(res.code == 200){
						this.close()
						this.$emit('getList');
					}
				}).catch((err) => {
					console.log(err, 222)
				})
			},
            // 关闭
			close() {
				this.ruleForm = {
					parent_id: '',
					sort: 1,
					type: '',
					cate_name: '',
					cate_sketch: '',
					cate_icon: ''
				}
				this.$emit('close');
			},
			// 选择图片成功
			uploadSuccess(e) {
				this.ruleForm.cate_icon = this.$constant.qnUrl + '/' + e.key
			},
		}
	};
</script>
