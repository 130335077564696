<template>
	<div class="wrapper" v-loading="loading">
		<div class="top-btn" ref="topBtn">
            <el-button size="small" type="primary" @click="add()">添加</el-button>
		</div>
		<el-table :data="list" :max-height="tableHeight" :header-cell-style="{background:'#f5f7fa'}" style="width: 100%">
			<el-table-column prop="id" label="分类id"></el-table-column>
			<el-table-column prop="cate_name" label="分类名称"></el-table-column>
			<el-table-column prop="sort" label="排序"></el-table-column>
			<el-table-column prop="parent_id" label="父级分类id"></el-table-column>
			<el-table-column prop="cover" label="分类背景照片">
				<template slot-scope="scope">
					<img :src="scope.row.cate_icon" style="width: 100px"/>
				</template>
			</el-table-column>
			<el-table-column prop="type" label="分类类型">
				<template slot-scope="scope">
					<span>{{ $constant.collegeType[scope.row.type] || '--'}}</span>
				</template>
			</el-table-column>
            <el-table-column label="操作">
				<template slot-scope="scope">
					<el-link type="primary" @click="edit(scope.row)" style="margin-right: 10px;">修改</el-link>
					<el-popconfirm
                        title="确定删除吗？"
                        @confirm="deleteClass(scope.row.id)">
                        <el-link type="primary" slot="reference">删除</el-link>
                    </el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
        <ClassAdd ref="add" :dialogVisible="dialogVisible" :typeFlag="typeFlag" :row="row" @getList="getList" @close="close"/>
	</div>
</template>
<script>
    import ClassAdd from './compontent/classAdd'
	import { cateList, delCate } from '@/api/college.js';
	export default {
		name: "ClassEdit",
		data() {
			return {
				loading: true,
				tableHeight: this.$constant.maxHeight,
				// 列表
				list: [],
                // 弹框
                typeFlag: 'add',
				dialogVisible: false,
				row: {}
			}
		},
		components: {
			ClassAdd
		},
		mounted() {
			this.getList()
			this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			window.onresize = this.$common.debounce(()=>{
				this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130)
			}, 1000)
		},
		methods: {
			// 添加
			add() {
				this.$refs.add.getallCate()
				this.typeFlag = 'add'
				this.row = {}
				this.dialogVisible = true
            },
            // 修改
			edit(row) {
				this.$refs.add.getallCate()
                this.typeFlag = 'edit'
				this.row = row
				this.dialogVisible = true
			},
			// 删除
			deleteClass(id) {
				delCate({ cate_id: id }).then((res) => {
					if(res.code == 200) {
						this.$message({ message: '删除成功', type: 'success' });
						this.getList()
					}
				}).catch((err) => {
					console.log(err, 222)
				})
			},
			// 获取列表
			getList() {
				this.loading = true
				cateList().then((res) => {
					this.list = res.data;
					this.loading = false
				}).catch((err) => {
					this.loading = false
					console.log(err, 222)
				})
			},
            // 关闭弹框
            close() {
                this.dialogVisible = false
            },
		}
	};
</script>